import { Component } from '@angular/core';
import { isRU } from 'src/texts/texts';

import { CommonLoaderService } from '../../services/common-loader.service';
import { PageComponentInterface } from '../../pages/page-component.interface';

@Component({
    selector: 'ca-gallery',
    templateUrl: 'gallery.component.html',
    styleUrls: ['gallery.component.less']
})
export class GalleryComponent {
    menu = [
        {
            title: 'Form Components',
            id: 'forms'
        },
        {
            title: 'Menu Components',
            id: 'menu'
        },
        {
            title: 'Cards',
            id: 'cards'
        },
        {
            title: 'Map Markers',
            id: 'markers'
        },
        {
            title: 'Tooltips',
            id: 'tooltips'
        },
        {
            title: 'Miscellaneous Components',
            id: 'misc'
        },
        {
            title: 'Buttons Components',
            id: 'buttons'
        },
        {
            title: 'Static Components',
            id: 'static'
        },
        {
            title: 'Timeline Components',
            id: 'timeline'
        },
    ];

    currentPage: {
        id: string;
        title: string;
        sections: string[];
    };

    isRu = isRU;

    showMobileMenu = false;

    constructor(commonLoader: CommonLoaderService) {
        commonLoader.hideHtmlLoader();
    }

    onRouterOutletActivate(c: Component & PageComponentInterface) {
        this.currentPage = {
            id: c.id,
            title: c.title,
            sections: Object.values(c.showcases).map(s => s.title)
        };

        this.showMobileMenu = false;
    }

    setLanguage(language: string) {
        localStorage.setItem('lang', language);
        location.reload();
    }
}
