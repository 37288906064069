import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
    ClickOutsideDirective,
    AfterInitDirective,
    ShowDirective, IsScrollTopDirective
} from './directives';

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        ClickOutsideDirective,
        AfterInitDirective,
        ShowDirective,
        IsScrollTopDirective,
    ],
    declarations: [
        ClickOutsideDirective,
        AfterInitDirective,
        ShowDirective,
        IsScrollTopDirective,
    ]
})
export class DirectivesModule {}
