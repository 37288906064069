import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DirectivesModule } from '../directives/directives.module';
import { ArrowModule } from '../modules/icons/components/arrow/arrow.module';
import { LangSelectorComponent } from './lang-selector/lang-selector.component';
import { TooltipsModule } from './tooltips/tooltips.module';
import { LangSelectorMobileComponent } from './lang-selector-mobile/lang-selector-mobile.component';
import { InputModule } from './input/input.module';
import { InputDropdownModule } from './input-dropdown/input-dropdown.module';
import { InputForDropdownModule } from './input-for-dropdown/input-for-dropdown.module';

@NgModule({
    imports: [
        CommonModule,
        DirectivesModule,
        FormsModule,
        ReactiveFormsModule,
        ArrowModule,
        TooltipsModule,
        InputModule,
        InputDropdownModule,
        InputForDropdownModule,
    ],
    declarations: [
        LangSelectorComponent,
        LangSelectorMobileComponent,
    ],
    exports: [
        InputModule,
        InputDropdownModule,
        InputForDropdownModule,
        LangSelectorComponent,
        LangSelectorMobileComponent,
    ]
})
export class SharedComponentsModule {
}
