import { NgModule, enableProdMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import localeRu from '@angular/common/locales/ru';

import { environment } from './environments/environment';
import { RoutingModule } from './routing.module';

import { SharedComponentsModule } from 'projects/shared/components/shared-components.module';
import { GalleryComponent } from './components/gallery/gallery.component';

if (environment.production) {
    enableProdMode();
}

registerLocaleData(localeRu);

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RoutingModule,
        SharedComponentsModule,
    ],
    declarations: [
        GalleryComponent,
    ],
    bootstrap: [GalleryComponent],
})
export class GalleryModule {}
