import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

const TIMEOUT = 200;

@Injectable({
    providedIn: 'root'
})
export class CommonLoaderService {
    constructor(@Inject(DOCUMENT) private document: Document) {}

    public hideHtmlLoader() {
        const { style } = this.document.getElementById('commonLoader');

        if (style) {
            style.opacity = '0';

            setTimeout(() => {
                style.display = 'none';
            }, TIMEOUT);
        }
    }
}
