import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InputDropdownComponent } from './input-dropdown.component';
import { InputForDropdownModule } from '../input-for-dropdown/input-for-dropdown.module';

@NgModule({
    imports: [
        CommonModule,
        InputForDropdownModule,
    ],
    exports: [InputDropdownComponent],
    declarations: [InputDropdownComponent],
})
export class InputDropdownModule {}
