<div class="gallery-container">
    <div class="gallery-menu-wrapper">
        <div class="gallery-menu--mobile">
            <div class="gallery-menu__section ellipsis" (click)="showMobileMenu = true">
                <a class="gallery-menu__link" routerLink="/{{currentPage?.id}}" routerLinkActive="active">
                    <span>({{currentPage?.sections.length || 0}})</span>
                    {{currentPage?.title}}
                </a>
            </div>
        </div>
        <div class="gallery-language">
            <lang-selector [setLangCb]="setLanguage" [isRu]="isRu"></lang-selector>
        </div>
    </div>
    <nav class="gallery-menu"
        [class.gallery-menu--mobile-popup]="showMobileMenu"
    >
        <div class="gallery-menu__section ellipsis"
            *ngFor="let menuItem of menu"
        >
            <a class="gallery-menu__link" routerLink="/{{menuItem.id}}" routerLinkActive="active">
                <span *ngIf="currentPage?.id === menuItem.id">({{currentPage?.sections.length || 0}})</span>
                {{menuItem.title}}
            </a>
            <nav class="gallery-menu__submenu" *ngIf="currentPage?.id === menuItem.id">
                <a class="gallery-menu__link gallery-menu__submenu__link"
                    *ngFor="let showcase of currentPage?.sections"
                    routerLink="/{{menuItem.id}}" [fragment]="showcase"
                    (click)="showMobileMenu = false"
                >
                    {{showcase}}
                </a>
            </nav>
        </div>
    </nav>
    <div class="gallery-content">
        <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
    </div>
</div>
