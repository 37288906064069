<div class="lang_selector"
    [class.lang_selector-mobile_banner]="isMobileBanner"
    (click)="isOpenSelectLang = !isOpenSelectLang"
    (clickOutside)="isOpenSelectLang = false"
>
    <div class="lang_selector__flag"
        [class.lang_selector__flag-mobile_banner]="isMobileBanner"
        [class.lang_selector__flag-ru]="isRu"
    ></div>
    <ca-arrow class="lang_selector__popup-indicator"
        [class.lang_selector__popup-indicator--mobile_banner]="isMobileBanner"
        size="small"
        [direction]="isOpenSelectLang ? 'up' : 'down'"
    ></ca-arrow>
    <div class="lang_selector__dropdown"
        [class.lang_selector__dropdown-open]="isOpenSelectLang"
        (click)="setLangCb(isRu ? 'en' : 'ru')"
    >
        <div class="lang_selector__flag"
            [class.lang_selector__flag-mobile_banner]="isMobileBanner"
            [class.lang_selector__flag-ru]="!isRu"
        ></div>
    </div>
</div>
