import {
    AQI,
    AqiType,
    CH2O,
    CO,
    CO2,
    H2S,
    HISTOGRAM_AQI,
    HUM, IZA,
    MeasureScheme,
    NH3,
    NO,
    NO2,
    O3,
    PDK,
    PM10,
    PM25,
    PRES,
    ROOM,
    SO2,
    StndAskPopupTexts,
    SUM,
    TEMP,
    TSP,
    WDA,
    WVA
} from 'projects/cityscreen/src/namespace';

export const ugM = 'мкг/м<sup>3</sup>';
export const mgM = 'мг/м<sup>3</sup>';
export const shareOfMPC = 'Доли ПДК';

// используется для группировки коефициентов графика
export const MEASURES = {
    [AQI]: 'баллы',
    [AqiType.indoor]: 'баллы',
    [AqiType.cityair]: 'баллы',
    [AqiType.instant]: 'баллы',
    [AqiType.epa]: 'баллы',
    [AqiType.CAPI]: 'баллы',
    [HISTOGRAM_AQI]: '% времени',
    [HUM]: '%',
    [PM10]: ugM,
    [PM25]: ugM,
    [SO2]: ugM,
    [CH2O]: ugM,
    [CO]: ugM,
    [CO2]: 'ppm',
    [O3]: ugM,
    [NO]: ugM,
    [H2S]: ugM,
    [NH3]: ugM,
    [NO2]: ugM,
    [TEMP]: '°C',
    [PRES]: 'мм&nbsp;рт.ст.',
    [WDA]: '°',
    [WVA]: 'м/с',
    [SUM]: ugM,
};

export const MEASURES_USA = {
    ...MEASURES,
    [TEMP]: '°F'
};

export const MEASURES_MG = {
    [AQI]: 'баллы',
    [AqiType.indoor]: 'баллы',
    [AqiType.cityair]: 'баллы',
    [AqiType.instant]: 'баллы',
    [AqiType.epa]: 'баллы',
    [AqiType.CAPI]: 'баллы',
    [HISTOGRAM_AQI]: '% времени',
    [HUM]: '%',
    [PM10]: mgM,
    [PM25]: mgM,
    [SO2]: mgM,
    [CH2O]: mgM,
    [CO]: mgM,
    [CO2]: 'ppm',
    [O3]: mgM,
    [NO]: mgM,
    [H2S]: mgM,
    [NH3]: mgM,
    [NO2]: mgM,
    [TEMP]: '°C',
    [PRES]: 'мм&nbsp;рт.ст.',
    [WDA]: '°',
    [WVA]: 'м/с',
    [SUM]: mgM,
};

export const MEASURES_MPC = {
    ...MEASURES,
    [PM10]: shareOfMPC,
    [PM25]: shareOfMPC,
    [SO2]: shareOfMPC,
    [CH2O]: shareOfMPC,
    [CO]: shareOfMPC,
    [O3]: shareOfMPC,
    [NO]: shareOfMPC,
    [H2S]: shareOfMPC,
    [NH3]: shareOfMPC,
    [NO2]: shareOfMPC,
    [SUM]: shareOfMPC,

};

export const UNITS = {
    '°C': '°C',
    '°F': '°F',
    'mm Hg': 'мм&nbsp;рт.ст.',
    '%': '%',
    'µg/m³': ugM,
    'mg/m³': mgM,
    ppm: 'ppm',
    mV: 'мВ',
    '°': '°',
    'm/s': 'м/c',
    'mm/min': 'мм/мин',
};

export const MEASURES_SCHEME = {
    [MeasureScheme.default]: MEASURES,
    [MeasureScheme.usa_default]: MEASURES_USA,
    [MeasureScheme.c_mmhg_mg]: MEASURES_MG,
    [MeasureScheme.mpc]: MEASURES_MPC,
};

export const AQI_DECL = ['балл<i style="visibility: hidden;">ов</i>', 'балла<i style="visibility: hidden;">в</i>', 'баллов', 'баллы'];
export const AQI_DECL2 = ['балл', 'балла', 'баллов', 'баллы'];

export const NAMES = {
    [AQI]: 'AQI',
    [AqiType.indoor]: 'IAQI',
    [AqiType.cityair]: 'cityair',
    [AqiType.instant]: 'AQI',
    [AqiType.epa]: 'EPA',
    [AqiType.CAPI]: 'КИЗА',
    [IZA]: 'ИЗА',
    [HISTOGRAM_AQI]: '',
    [HUM]: 'RH',
    [PM10]: 'PM10',
    [PM25]: 'PM2.5',
    [SO2]: 'SO<sub>2</sub>',
    [CO]: 'CO',
    [CO2]: 'CO<sub>2</sub>',
    [O3]: 'O<sub>3</sub>',
    [NO]: 'NO',
    [H2S]: 'H<sub>2</sub>S',
    [NH3]: 'NH<sub>3</sub>',
    [NO2]: 'NO<sub>2</sub>',
    [CH2O]: 'CH<sub>2</sub>O',
    [TEMP]: 'T',
    [PRES]: 'P',
    [PDK]: 'ПДК',
    [SUM]: 'Сум.',
    [TSP]: 'TSP',
    [WDA]: 'Направление ветра',
    [WVA]: 'Скорость ветра',
};
export const MMT_LONG_NAMES = {
    [TEMP]: 'Температура',
    [HUM]: 'Влажность',
    [PRES]: 'Давление',
};

const links = 'и использовать для <a target="_blank" href="https://tion.ru/product/breezer/">проветривания</a> и <a target="_blank" href="https://tion.ru/product/clever/">очистки</a> воздуха в помещении специальные бытовые приборы.';
export const AQI_TEXTS = [
    ['Нет данных', 'Нет данных'],
    ['Отлично', 'Воздух полностью безопасен.'],
    ['Хорошо', `Воздух безопасен для большинства людей.`],
    ['Нормально', `Особо чувствительным к качеству воздуха людям (например, аллергикам) рекомендуется ограничить время пребывания на улице.`],
    ['Требует осмотрительности', `Воздух может быть вреден для аллергиков, детей, пожилых людей, а также для людей с заболеваниями легких или сердца. Рекомендуется ограничить время пребывания на улице.`],
    ['Небезопасно для аллергиков', `Воздух вреден для аллергиков, детей, пожилых людей, а также для людей с заболеваниями легких или сердца. Рекомендуется избегать пребывания на улице.`],
    ['Небезопасно', `
        Воздух может быть опасен для здоровья. Рекомендуется ограничить время пребывания на улице ${links}`],
    ['Вредно', `Воздух загрязнен и вреден для здоровья. Рекомендуется избегать пребывания на улице ${links}`],
    ['Очень вредно', `
        Высокий уровень загрязнения воздуха. Рекомендуется избегать пребывания на открытом воздухе,
        пользоваться средствами защиты на улице ${links}
    `],
    ['Опасно для здоровья', `
        Высокий уровень загрязнения воздуха. Возможно появление симптомов интоксикации.
        Рекомендуется избегать пребывания на открытом воздухе, пользоваться средствами защиты на улице ${links}
    `],
    ['Критически опасно', `
        Крайне высокий уровень загрязнения воздуха. Рекомендуется воздержаться от появления на улице ${links}
    `]
];

export const AQI_THREE_TEXTS = ['Безопасно', 'Терпимо', 'Опасно'];
export const AQI_THREE_TEXTS2 = ['Чистый воздух', 'Среднее количество примесей', 'Большое количество примесей'];

export const CAPI_GRADES = ['0 – 4', '5 – 6', '7 – 13', '> 14'];
export const CAPI_THREE_TEXTS = ['Низкое загрязнение', 'Повышенное загрязнение', 'Высокое загрязнение', 'Очень высокое загрязнение'];

export const TOOLTIPS = {
    ...NAMES,
    [AQI]: 'Air quality index - Индекс качества воздуха',
    [PM25]: 'Particulate Matter - взвешенные частицы размером не более 2.5 мкм',
    [PM10]: 'Particulate Matter - взвешенные частицы размером не более 10 мкм',
    [CO2]: 'Углекислый газ',
    [TEMP]: 'Температура',
    [HUM]: 'Relative Humidity - относительная влажность',
    [PRES]: 'Давление',
    [HUM]: 'Влажность'
};

export const TOOLTIPS_MONITOR = {
    [AQI]: ' - индекс качества воздуха',
    [PM25]: ' - это твёрдые частицы размером менее 2,5 микрон',
    [PM10]: '- это твёрдые частицы размером от 2,5 до 10 микрон'
};
export const PM_DESCRIPTION = 'PM (Particulate matter) — смесь частиц пыли, золы, сажи, а также сульфатов и нитратов, находящихся во взвешенном состоянии в воздухе. Если вы замечали смог в центре крупного мегаполиса, знайте — это частицы PM2,5 и PM10';

export const LAST_DATA_AGO = ['Последнее обновление ', 'ч. назад'];

export const COMMON = {
    actions: 'Действия',
    today: 'cегодня',
    yesterday: 'вчера',
    error: 'Ошибка',
    apply: 'Применить',
    save: 'Сохранить',
    doNotSave: 'Не сохранять',
    cancel: 'Отмена',
    close: 'Закрыть',
    closeChart: 'Закрыть график',
    delete: 'Удалить',
    gas: 'Газы',
    more: 'более',
    back: 'вернуться',
    placeholderDate1: 'Дата начала периода',
    placeholderDate2: 'Дата окончания периода',
    browserNotSupport: 'Ваш браузер не поддерживается',
    byTheCity: 'по городу',
    pm2: 'PM2.5',
    pm10: 'PM10',
    cities: 'Города',
    city: 'Город',
    location: 'Местоположение',
    allWorld: 'Весь мир',
    allCities: 'Все города',
    airQuality: 'Качество воздуха.',
    pdkMr: 'ПДКмр',
    pdkSs: 'ПДКсс',
    logOut: 'Выход',
    noGroupPermission: 'Нет доступа в данную сеть мониторинга',
    now: 'Сейчас',
};

export const TIME_NAMES = {
    month: 'Месяц',
    week: 'Неделя',
    days3: `72&nbsp;Часа`,
    watches24: `24&nbsp;Часа`,
    min5: '5 мин',
    min20: '20 мин',
    hour1: '1 час',
    day1: '1 день'
};
export const TIME_NAMES_TOOLTIP = {
    min5: 'Доступно для периода наблюдения менее 3 дней',
    min20: 'Доступно для периода наблюдения менее 12 дней',
    hour1: 'Доступно для периода наблюдения менее 36 дней',
    day1: ''
};
export const STATION_BLOCK = {
    returnScale: 'Вернуть масштаб',
    dataOnPollutants: 'Данные о загрязнителях/метеоданные',
    addToComparison: 'Сравнить',
    compare: 'Режим сравнения',
    removeCompare: 'Убрать из сравнения',
    addCompare: 'Добавить к сравнению',
    type: {
        mo: 'Станция Cityair',
        myMo: 'Станция Cityair',
        station: 'Станция Cityair',
        control_point: 'Результаты расчёта',
        OpenAQ: 'OpenAQ',
        city: '',
    },
    noData: 'Нет данных измерений за указанный период',
    averageCity: 'Среднее по городу',
    add: 'Добавить',
    select: 'Выбрать'
};

export const TEXT_DESCRIPTION = {
    infoBlock: ['Температура', 'Давление', 'Влажность'],
    airQualityTitle: 'Air Quality Index (Индекс Качества Воздуха)',
    airQuality: `Качество воздуха определяется<br>
            по международной шкале AQI:<br>
            от 1 (чистый) до 10 (очень грязный)`,
    capiAirQuality: `Комплексный индекс загрязнения атмосферы`
};

export const CHART_OPTIONS = {
    lang: {
        loading: 'Загрузка...',
        months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
        shortMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
        exportButtonTitle: 'Экспорт',
        contextButtonTitle: 'Экспорт',
        printButtonTitle: 'Печать',
        rangeSelectorFrom: 'С',
        rangeSelectorTo: 'По',
        rangeSelectorZoom: 'Период',
        downloadPNG: 'Скачать PNG',
        downloadJPEG: 'Скачать JPEG',
        downloadPDF: 'Скачать PDF',
        downloadSVG: 'Скачать SVG',
        printChart: 'Напечатать график',
        fullscreen: 'fullscreen',
        resetZoom: 'Вернуть масштаб',
        resetZoomTitle: 'Вернуть 100% масштаб графика'
    }
};

export const MARKET = {
    text: 'Публичная карта теперь доступна в новом приложении',
    airvoice: 'AirVoice',
    linkApple: 'https://apps.apple.com/ru/app/airvoice/id1484315084',
    linkGoogle: 'https://play.google.com/store/apps/details?id=com.cityair.airvoice',
};

export const WEEKDAY = {
    mo: 'Пн',
    tu: 'Вт',
    we: 'Ср',
    th: 'Чт',
    fr: 'Пт',
    sa: 'Сб',
    su: 'Вс'
};

export const DOWNLOAD_POPUP = {
    title: 'Первичные данные',
    period: 'Выберите период',
    all: 'со всех постов',
    all_devices: 'со всех устройств',
    several: 'с выбранных постов',
    several_devices: 'с выбранных устройств',
    select: 'Укажите посты',
    select_devices: 'Укажите устройства',
    select_type: 'Выберите формат',
    selected: 'Выбрано: ',
    download: 'Скачать',
    dateRange: 'Интервал данных',
    unitsMeasurements: 'Единицы измерения',
    error14day: 'Временной интервал для скачивания не может превышать 14 дней',

    filenameDataMO: 'AQM_data',
    filenameDataDevice: 'device_data',
    filenameDiagnostic: 'device_diagnostic_data',
    filenameReport: 'AQM_report_TZA4',
    error: 'К сожалению, при загрузке данных произошла ошибка. Повторите попытку позже или обратитесь в сервисную службу'
};

export const GMT_VALUE = [
    [-12, '(GMT -12:00) Эниветок, Кваджалейн'],
    [-11, '(GMT -11:00) Остров Мидуэй, Самоа'],
    [-10, '(GMT -10:00) Гавайи'],
    [-9, '(GMT -9:00) Аляска'],
    [-8, '(GMT -8:00) Тихоокеанское время (США и Канада)'],
    [-7, '(GMT -7:00) Горное время (США и Канада)'],
    [-6, '(GMT -6:00) Центральное время (США и Канада), Мехико'],
    [-5, '(GMT -5:00) Восточное время (США и Канада), Богота, Лима'],
    [-4, '(GMT -4:00) Атлантическое время (Канада), Каракас, Ла-Пас'],
    [-3, '(GMT -3:00) Бразилия, Буэнос-Айрес, Джорджтаун'],
    [-2, '(GMT -2:00) Срединно-Атлантического'],
    [-1, '(GMT -1:00 час) Азорские острова, острова Зеленого Мыса'],
    [0, '(GMT) Лондон, Лиссабон, Касабланка'],
    [1, '(GMT +1:00 час) Брюссель, Копенгаген, Мадрид, Париж'],
    [2, '(GMT +2:00) Киев, Калининград, Южная Африка '],
    [3, '(GMT +3:00) Москва, Санкт-Петербург'],
    [4, '(GMT +4:00) Абу-Даби, Мускат, Баку, Тбилиси'],
    [5, '(GMT +5:00) Екатеринбург, Исламабад, Карачи, Ташкент'],
    [6, '(GMT +6:00) Алматы, Дакке, Коломбо'],
    [7, '(GMT +7:00) Новосибирск, Красноярск, Кемерово'],
    [8, '(GMT +8:00) Пекин, Перт, Сингапур, Гонконг'],
    [9, '(GMT +9:00) Токио, Сеул, Осака, Саппоро, Якутск'],
    [10, '(GMT +10:00) Восточная Австралия, Гуам, Владивосток '],
    [11, '(GMT +11:00) Магадан, Соломоновы острова, Новая Каледония'],
    [12, '(GMT +12:00) Окленд, Веллингтон, Фиджи, Камчатка']
];

export const PROFILE = {
    network: 'Сеть мониторинга',
    post: {
        zero: 'постов мониторинга',
        one: 'пост мониторинга',
        two: 'поста мониторинга',
        few: 'поста мониторинга',
        many: 'постов мониторинга',
        other: 'постов мониторинга'
    },
    support: 'Служба поддержки',
    phone: ['+74955405516', '+7&nbsp;495&nbsp;540&nbsp;55&nbsp;16'],
    email: 'info@cityair.ru',
    copyright: 'CityAir. Все права защищены',
};

export const ADMIN_PANEL = {
    listDevices: 'Управление и диагностика',
    listOM: 'Посты мониторинга',
    dashboard: 'Монитор состояния сети',
    OM: 'Посты',
    eventList: 'Список событий.<br>Раздел в разработке',
    notlistDevices: '.<br>Устройства в списке отсутствуют',
    notlistOM: '.<br>"Посты мониторинга" в списке отсутствуют',
    notlistUsers: '.<br>В списке нет ни одного пользоваеля',
    noteventList: '',
    notcityCard: '',
    notcoefficientView: '',
    coefficientView: 'Поправочные коэффициенты',
    listUsers: 'Пользователи',
    cityCard: 'Аналитика',
    plumes: 'Шлейфы',
    indoor: 'Indoor',
    reports: 'Отчеты',
    forecast: 'Прогнозы',
    notindoor: '',
    configPanel: 'Настройки',
    buttonAddMO: 'Создать пост мониторинга',
    buttonAddUser: 'Создать пользователя',
    notifications: 'Центр уведомлений',
    notnotifications: '',
    postsAndDevices: 'Посты и устройства',
    back: 'Назад'
};

export const ANALYTICS_PAGE = {
    searchPlaceholder: 'Поиск'
};

export const SERVICE_PAGE = {
    searchPlaceholder: 'Поиск поста мониторинга, устройства',
    monitoringObjects: 'Посты мониторинга',
    devices: 'Устройства'
};

export const LIST_OM = {
    objects: 'Сеть мониторинга',
    objectsView: 'Пост мониторинга',
    filterForName: 'Поиск',
    notPublic: 'Не Публичная',
    public: 'Публичная',
    offline: 'Оффлайн',
    online: 'Онлайн',
    clearFilter: 'Очистить фильтр',
    countStation: 'Количество',
    owner: 'Владелец',
    operator: 'Оператор',
    observer: 'Наблюдатель',
    observer_at: 'ПМ',
    selectOm: 'Нажмите для назначения Постов Мониторинка <br> данному пользователю',
    changesSaved: 'Изменения успешно сохранены',
    checkAll: 'Выбрать всё',
    uncheckAll: 'Убрать всё',
    noRole: 'Нет доступа',
    worked: 'Работает в штатном режиме',
    notWorked: 'Нет сигнала',
    batWorked: 'От батареи',
    batLow: 'Низкий заряд батареи',
    noData: 'Нет данных',
    descriptionSection: `В данной вкладке отображается список доступных вам \"постов мониторинга\" (точки на карте).<br>К одному посту мониторинга могут быть привязаны несколько устройств, например<br>станция мониторинга CityAir и модуль измерения газов.`,
    noDevices: 'Нет привязанных устройств',
    noPosts: 'Нет доступных постов мониторинга',
    noFound: 'Постов мониторинга не найдено',
    noFoundDescription: 'По вашему запросу ничего не найдено, попробуйте поискать по другим ключевым словам',
};

export const POPUP_DELETE: StndAskPopupTexts = {
    title: 'Удаление Поста Мониторинга',
    body: 'Вы действительно хотите удалить этот пост мониторинга?',
    cancel: 'Я передумал',
    cancelNew: COMMON.cancel,
    accept: COMMON.delete
};

export const POPUP_DELETE_USER: StndAskPopupTexts = {
    title: 'Удаление пользователя',
    body: 'Вы действительно хотите удалить этого пользователя?',
    cancel: 'Я передумал',
    accept: COMMON.delete
};

export const POPUP_THREE_QUESTIONS: StndAskPopupTexts = {
    title: 'У вас есть несохраненные изменения',
    body: 'Вы действительно хотите покинуть окно редактирования? Внесенные изменения не сохранятся',
    accept: 'Сохранить настройки',
    cancel: 'Продолжить редактирование',
    extraAction: COMMON.doNotSave
};

export const EDIT_STATION = {
    accommodationObjects: 'Посты мониторинга',
    edit: 'Редактировать',
    delete: COMMON.delete,
    editing: 'Редактированние',
    name: 'Название',
    public: 'Публичная',
    publicName: 'Имя на карте',
    coordinates: 'Координаты',
    description: 'Описание',
    timezone: 'Часовой пояс',
    saveData: 'Сохранять данные',
    downloadXLSData: 'Скачать данные',
    downloadReport: 'Скачать отчет ТЗА4',
    documentation: 'Документация',
    guideOperationName: 'Руководство по эксплуатации станций.pdf',
    guideOperationLink: 'https://my.cityair.io/docs/Manual.pdf',
    viewSite: 'Посмотреть на сайте',
    stationScheme: 'Схема станции.pdf',
    cancel: COMMON.cancel,
    save: COMMON.save,
    technicalInfo: 'Техническая информация ',
    model: 'Модель',
    versionH: 'Версия Hardware',
    versionS: 'Версия Software',
    startTimeStation: 'Запуск в эксплуатацию',
    lastTimeStation: 'Последний пакет',
    scheduledMaintenance: 'Плановое сервисное обслуживание',
    linkSpecification: 'https://my.cityair.io/docs/Station Design_ru.pdf',
    textareaPlaceholderAdmin: 'Пользовательское описание объекта. Нажмите "редактировать", чтобы добавить описание.',
    textareaPlaceholder: 'Пользовательское описание объекта.',
    openChart: 'Открыть график',
    error: 'Ошибка сохранения. Сообщите службе поддержки.',
    listDevices: 'Устройство',
    partOf: 'Входит в состав постов мониторинга'
};

export const LIST_DEVICES = {
    selectDevice: 'Выберите устройство',
    selectDevices: 'Выберите устройствa',
    info: 'Информация',
    name: 'Имя',
    lat: 'Широта',
    lng: 'Долгота',
    timezone: 'Часовой пояс',
    location: 'Локация',
    listDevices: 'Список устройств',
    startDate: 'Запуск в эксплуатацию',
    service: 'Сервисное обслуживание',
    descriptionSection: `В данной вкладке отображаются все доступные вам устройства измерения<br>качества воздуха: станции мониторинга CityAir, модули расширения<br>G1/G2 для измерения газов, прочие устройства.`,
    connected: 'Посты мониторинга',
    notConnected: 'Станция нигде не установлена',
    loadPrimaryData: 'Скачать первичные данные',
    loadReport: 'Скачать отчёт',
    loadLog: 'Журнал диагностики',
    paramDeviceWork: 'Параметры работы устройства',
    interval: 'Интервал измерений',
    saveData: 'Сохранение данных',
    savingData: 'Cохранять данные',
    off: 'Выключено',
    on: 'Включено',
    min: ' мин',
    serialNumber: 'Серийный номер',
    type: 'Тип',
    online: 'На связи',
    offline: 'Нет сигнала',
    condition: 'Состояние',
    relatedDevices: 'Связанные устройства',
    since: 'с',
    noDevices: 'Нет доступных постов мониторинга',
    noFound: 'Устройств не найдено',
    equipmentNotAttached: `Оборудование&nbsp;не&nbsp;прикреплено&nbsp;к&nbsp;посту`,
    noConnectedDevices: `Нет&nbsp;связанных&nbsp;устройств`
};

export const USER_ACCESS_TYPE = ['Нет доступа', 'Администратор', 'Оператор', 'Наблюдатель', 'Сервис'];

export const LIST_USERS = {
    listUsers: 'Пользователи',
    roleUser: 'Роли пользователя',
    deleteUser: 'Удалить пользователя',
    addUsers: 'Добавление пользователя',
    editUsers: 'Редактирование пользователя',
    login: 'Логин',
    mail: 'e-mail',
    emailInGroup: 'Этот email уже используется в&nbsp;данной сети мониторинга',
    incorrectEmail: 'Неправильный email',
    invalidLogin: 'Неправильный логин',
    access: 'Доступ',
    user: 'Пользователь',
    add: 'Добавить',
    create: 'Создать',
    operator: `Операторы имеют доступ к&nbsp;просмотру данных и&nbsp;управлению постами мониторинга`,
    observer: `Наблюдатели имеют доступ только к&nbsp;просмотру данных с&nbsp;доступных им&nbsp;постов мониторинга`,
    noSelect: 'Не выбрано',
    accessSelect: 'Уровень доступа',
    whenSaving: 'Изменения сохранены',
    whenAdd: 'Пользователь добавлен',
    whenCreate: 'Инструкции по активации учётной записи будут высланы на указанный адрес электронной почты'
};

export const CREATE_OM = {
    createOm: 'Создание поста мониторинга',
    editOm: 'Редактирование поста мониторинга',
    users: 'Пользователи',
    operators: 'Операторы',
    observers: 'Наблюдатели',
    descriptionSection: 'В данной вкладке вы можете создать новый пост мониторинга,<br>на основе имеющихся у вас устройств, доступных во вкладке "Списке устройств".'
};

export const LANDINGS = {
    info: 'Сейчас',
    PM10: '(частицы пыли размером не более 10 мкм)',
    PM2: '(частицы пыли размером не более 2,5 мкм)',
    HISTOGRAM_AQI: `Статистика качества воздуха за месяц`,
    last: 'За последние 3 дня'
};

export const SHARING = {
    title: 'Делимся ссылкой',
    description: 'По данной ссылке будет доступна информация обо всех текущих параметрах карты: геолокация, время, посты мониторинга.',
    toWhom: 'Кому:',
    copy: 'Копировать',
    send: 'Отправить'
};

export const MODELS = {
    accommodationObjects: 'Посты мониторинга',
    simulation: 'Моделирование',
    info: `Внимание: математические модели работают в экспериментальном режиме`,
    info2: `Это значит, что сейчас мы щепетильно сравниваем результаты построений наших <br> математических моделей с данными, полученными со станций CityAir в этом регионе. <br>
        Чуть позже точность расчетов станет близкой к реальным показателям загрязненности воздуха`,
    noData: 'В данной области расчеты пока не осуществляются',
    title: 'Результаты моделирования',
    overlay: 'Непрерывно',
    contours: 'Контурно',
    off: 'Выкл',
    on: 'Вкл',
    border: 'Граница расчетной области'
};

export const CITY_CARD = {
    intro: [
        'Информация от создателей',
        'Мы&nbsp;рады делиться знаниями о&nbsp;качестве воздуха в&nbsp;разных городах мира',
        'В основе этой карты — данные платформы CityAir',
        'Сети мониторинга'
    ],
    backBtnCityScreen: 'Назад к списку',
    backBtn: ['Назад к списку городов', 'Назад к карте мира'],
    openCity: 'Заглянуть в город',
    goOver: 'Перейти',
    goOverCity: 'Перейти в город',
    close: 'закрыть',
    tooltip: [
        'Качество воздуха определяется по международной шкале AQI:<br> от 1 (чистый) до 10 (очень грязный)',
        'Общая доля каждого значения AQI',
        'Средние значения AQI по часам суток за всю историю наблюдений',
        'Средние значения AQI по дням недели за всю историю наблюдений'
    ],
    since2: ['Наблюдения ведутся с ', ''],
    nowInCity: 'Сейчас в городе',
    tabs: ['Посты мониторинга', 'Экологический профиль'],
    sorting: ['Города', 'Посты'],
    cityProfile: 'Экологический профиль',
    seasons: ['За год', 'Зима', 'Весна', 'Лето', 'Осень'],
    charts: [
        'Качество воздуха за всю историю наблюдений',
        'Среднее качество воздуха по часам суток',
        'Среднее качество воздуха по дням недели',
    ],
    noData: 'Нет данных за выбранный период времени',
    dataSources: 'Источники данных',
    noDataSources: 'Нет источников данных',
    noConnectedDevices: 'Данные станции CityAir'
};

export const COEFFICIENT_VIEW = {
    title: 'Поправочный коэффициент',
    content: [
        `Калибровка массовой концентрации взвешенных частиц для анализатора CityAir проводится по результатам параллельных измерений и состоит в определении двух коэффициентов: PCF (Photometric Calibration Factor) и SCF (Size Calibration Factor). В качестве опорного метода для определения коэффициентов рекомендуется использовать гравиметрический метод или любой эквивалентный.`,
        `Нормировочные коэффициенты определяются так, чтобы выполнялись следующие соотношения:`,
        `PM2.5<sup>Ref</sup> = PCF ⋅ PM2.5<sup>CityAir</sup>`,
        `PM10<sup>Ref</sup> = SCF<sup>3</sup> ⋅ PM10<sup>CityAir</sup> + (PCF - SCF<sup>3</sup> ) ⋅ PM2.5<sup>CityAir</sup>`,
        `где PM<sup>Ref</sup> и PM<sup>CityAir</sup> — результаты определения концентрации взвешенных веществ гравиметрическим методом с селективными импакторами, предназначенными для определения PM2.5 и PM10, и анализатором CityAir за один и тот же период времени.`,
    ],
    current: 'Текущий коэффициент PCF:',
    new: ['Задать новый коэффициент:', 'PCF', 'введите значение'],
    scf: 'Текущий коэффициент SCF<sup>3</sup>:',
    scfComment: 'Коэффициент SCF может быть настроен только при наличии синхронных гравиметрических измерений  PM2.5 и PM10',
    description: 'Поправочные коэффициенты изменяет только данные, поступающие в посты мониторинга. Первичные данные полученные с устройств мониторинга доступны в личном кабинете и через API для скачивания.',
    popup: ['Вы собираетесь изменить поправочный коэффициент', 'Новый коэффициент PCF:'],
    errorPcf: 'Для установки значения PCF вне пределов стандартного диапазона, пожалуйста, обратитесь в службу сервиса.'
};

export const QUALITY_CURRENT = [
    [
        'Распахни окна! Воздух чист',
        'Подходящий момент, чтобы принять воздушную ванну',
        'Хорошие условия для занятий спортом на&nbsp;воздухе!'
    ],
    [
        'Бывает и&nbsp;лучше: примеси есть, но&nbsp;пока до&nbsp;критической ситуации далеко',
        'Обычный день. Ни&nbsp;хорошо, ни&nbsp;плохо',
        'Можно прогуляться, но&nbsp;не&nbsp;слишком долго',
        'На&nbsp;улицу можно, но&nbsp;лучше там не задерживаться',
    ],
    [
        'Внимание! Воздух грязный!',
        'Если есть возможность&nbsp;&mdash; на&nbsp;улицу лучше не&nbsp;выходить и&nbsp;закрыть окна',
        'Не&nbsp;рекомендуем выходить на&nbsp;улицу',
        'Тьма, пришедшая со&nbsp;Средиземного моря, накрыла Ершалаим',
        'Облокотиться на&nbsp;такой воздух еще нельзя, но&nbsp;кружку в&nbsp;руке уже может быть не&nbsp;видно',
    ]
];

export const QUALITY_FORECAST = {
    'now<last': [
        ['Ожидается улучшение'],
        ['Ожидается улучшение'],
        ['Ожидается улучшение'],
    ],
    'now=last': [
        ['Ситуация стабильна'],
        ['Ситуация стабильна'],
        ['Ситуация стабильна'],
    ],
    'now>last': [
        ['Ожидается ухудшение'],
        ['Ожидается ухудшение'],
        ['Ожидается ухудшение'],
    ]
};

export const LOGIN_WINDOWS = {
    welcome: `Добро пожаловать<br>в&nbsp;CityAir`,
    toolsFor: 'Инструменты для настройки вашей сети мониторинга',
    enterNewPass: 'Введите новый пароль',
    newPass: 'Новый пароль',
    confNewPass: 'Подтвердите пароль',
    wrongNewPass: 'Пароль содержит недопустимые символы',
    passwordsDontMatch: 'Пароли не совпадают',
    warningNewPass: `<strong>Совет</strong>: пароль должен содержать минимум 7 символов. Чтобы&nbsp;сделать его более надёжным, используйте строчные и прописные латинские буквы, цифры и спецсимволы.`,
    resetPass: 'Сменить пароль',
    techSup: 'Техподдержка',
    passLevel: ['', 'простой', 'средний', 'средний', 'сложный', 'сложный'],
    wrong: 'Неправильный логин или пароль',
    wrongMail: 'Неправильный адрес почты',
    wrongCode: 'Неправильный проверочный код',
    cityscreenSignIn: 'Войти в CityAir',
    login: 'Логин',
    logout: 'Выйти',
    password: 'Пароль',
    forgot: 'Забыли пароль?',
    sign: 'Войти',
    forgotYourPass: 'Забыли пароль?',
    pleaseConfirm: `Введите адрес электронной почты вашего аккаунта, вам будет отправлен код подтверждения`,
    emailAddress: 'Адрес электронной почты',
    sendReset: `Далее`,
    backToSign: 'Вернуться к странице входа',
    language: 'Язык',
    en: 'English',
    ru: 'Русский',
    passUpd: 'Пароль изменен',
    yourPassHasBeen: 'Используйте его для входа в&nbsp;систему',
    check: 'Проверьте электронную почту',
    pleaseChk: `Инструкции по&nbsp;смене пароля были отправлены на&nbsp;вашу электронную почту`,
    info: `+7 495 540 55 16<br>info@cityair.ru`,
    licenseStr1: 'Лицензия Росгидромета',
    licenseStr2: '№ Р/2020/4163/100/Л от 23.11.2020',
    licenseStr3: `©${new Date().getFullYear()} CityAir. Все права защищены`,
    resetPassTitle: 'Восстановление пароля',
    weHaveSent: `Мы&nbsp;отправили код подтверждения на&nbsp;вашу электронную почту`,
    verCode: 'Код подтверждения',
    next: 'Дальше',

    mobileIsNotAvailable: 'Мобильная версия недоступна',
    suggestionForMobileClients: 'Откройте, пожалуйста, CityAir на ноутбуке или настольном компьютере',
    suggestionForMobileClientsNoBranding: 'Откройте, пожалуйста, личный кабинет на ноутбуке или настольном компьютере',

    toolsFor_sakhalin: 'Министерство экологии Сахалинской области',
    clientSignIn: 'Войти в личный кабинет',

    errorLink: 'У вас уже есть аккаунт в CityAir. Введите или восстановите логин и пароль',
};

export const REQUEST_ERRORS = {
    unrecognizedError: 'Неизвестная ошибка',
    tryAgainLater: 'Попробуйте ещё раз позже',
    clientOrNetworkError: 'Ошибка сети или обозревателя',
};

export const LOGIN_NORNICKEL = {
    contactSupport: 'Телефон службы поддержки',
    supportTel: '8 (800) 600-78-08',
    supportAvailable: 'ПН-ПТ с 9:00 до 18:00 (мск)',
    contactFeedback: 'Оставить отзыв',
    feedbackEmail: 'feedback@mail.city.online',
    contactHelp: 'Получить помощь',
    helpEmail: 'help@mail.city.online',
    copyright: `© 2008-${new Date().getFullYear()} ПАО «ГМК «Норильский никель»`
};

export const NOTIFICATIONS = {
    allMonitoringPostsSelected: 'Выбраны все посты',
    accommodationObjects: 'Посты мониторинга',
    back: 'Назад',
    settingNotification: 'Мои оповещения',
    eventList: 'Лента событий',
    eventsNotFound: 'Событий не найдено',
    eventsNotFoundHint: 'Поищите по другим ключевым словам или уберите<br/> примененные фильтры',
    all: 'Все',
    measures: 'Измерения',
    service: 'Сервисные',
    forecasts: 'Прогнозы',
    plumes: 'Шлейфы',
    earlier: 'Более ранние события',
    collapse: 'свернуть',
    searchResultEmpty: 'Совпадений не найдено',
    subscribeSuggestion: 'Настроить оповещения',
    eventsHelpMessageMeasurements: 'Настройте оповещения об измерениях, чтобы быть в курсе важных событий',
    eventsHelpMessageService: 'Настройте сервисные оповещения, чтобы быть в курсе важных событий',
    addSubscription: 'Добавить оповещение',
    activeSubscriptions: 'Активных оповещений',
    addSubscriptionHint: 'Чтобы создать новое оповещение, нажмите на кнопку «Добавить оповещение»',
    measuresDescription1: 'Получайте оповещения о&nbsp;превышениях заданных&nbsp;концентраций на&nbsp;постах мониторинга',
    measuresDescription2: 'Если вам подходят стандартные настройки, нажмите «Сохранить»',
    measuresDescription3: 'Если вы хотите изменить предложенные настройки для оповещений, нажмите «Редактировать»',
    serviceDescription1: 'Получайте оповещения о&nbsp;сбоях в работе оборудования&nbsp;и другую сервисную информацию',
    serviceDescription2: 'Если вам подходят стандартные настройки, нажмите «Сохранить»',
    serviceDescription3: 'Если вы хотите изменить предложенные настройки для оповещений, нажмите «Редактировать»',
    serviceHelpText: 'Если вы не хотите получать уведомления, удалите свой email в настройках оповещения',
    emailListInputLabel: 'Email (вы можете указать несколько через запятую)',
    noEventsAvailable: 'У вас пока нет событий',
    moNotFoundOrDeleted: 'Пост не найден или удален',
    subscriptionsPdkTooltip: {
        title: 'ПДКмр — максимальная разовая предельно&nbsp;допустимая концентрация',
        description: 'В соответствии с РД 52.04.667-2005',
        text: [
            'от 0 до 1 ПДК — низкое загрязнение',
            'от 2 до 4 ПДК — повышенное загрязнение',
            'от 5 до 10 ПДК — высокое загрязнение',
            'более 10 ПДК — очень высокое'
        ]
    },

    allPostMeasure: 'Все посты. Измерения',
    allPost: 'Все посты',
    allStation: 'Все станции',
    parameterForNotif: 'Параметры для оповещения',
    levelExcess: 'Уровень превышения',
    recipients: 'Получатели',
    edit: 'Редактировать',
    save: COMMON.save,
    cancel: COMMON.cancel,
    deleteSubscription: 'Удалить оповещение',
    addToEventFeed: 'Показывать в ленте событий',
    removeFromEventFeed: 'Убрать из ленты событий',
    newSubscription: 'Новое оповещение',
    editSubscription: 'Редактирование оповещения',
    activateSubscription: 'Возобновить оповещение',
    deactivateSubscription: 'Приостановить оповещение',
    titleSubscription: 'Имя оповещения',
    substances: 'Вещества',
    selectPosts: 'Выберите посты мониторинга из списка или на карте',
    selectPostsSmall: 'Выберите посты мониторинга',
    selectAll: 'Выбрать все',
    add: 'Добавить',
    controlledSubstances: 'Контролируемые вещества',
    excessLevel: 'Уровень превышения, начиная с которого мы будем вас оповещать',
    advancedSetup: 'Расширенная настройка',
    getToBasicSetup: 'Вернуться к базовой настройке',
    moreThan: 'более',
    showMore: 'ещё',
    selected: {
        zero: 'Выбрано',
        one: 'Выбран',
        two: 'Выбрано',
        few: 'Выбрано',
        many: 'Выбрано',
        other: 'Выбрано'
    },
    selectedStation: {
        zero: 'Выбрано',
        one: 'Выбрано',
        two: 'Выбрано',
        few: 'Выбрано',
        many: 'Выбрано',
        other: 'Выбрано'
    },
    noneSelected: 'Посты не выбраны',
    post: {
        zero: 'постов',
        one: 'пост',
        two: 'поста',
        few: 'поста',
        many: 'постов',
        other: 'постов'
    },
    station: {
        zero: 'устройств',
        one: 'устройство',
        two: 'устройства',
        few: 'устройства',
        many: 'устройств',
        other: 'устройств'
    },
    setManually: 'Задать вручную (мкг/м3)',
    notifyPeriodically: 'Оповещать о каждом интервале измерений (20 мин)',
    notifyEvent: 'Оповещать только о начале и окончании события',
    Slack: 'Slack',
    SMS: 'SMS',
    Email: 'Email',
    specifyFew: 'Укажите несколько через запятую',
    dailyReport: 'Отчет за день',
    whenSaving: '* При сохранении настроек будет выслано тестовое сообщение',
    emailsNotSpecified: 'не указаны',
    searchMoPlaceholderText: 'Искать пост мониторинга',
    stationSelectionPrompt: 'Выберите устройства из списка ниже',
    postsFilterPlaceholder: 'Поиск по посту мониторинга',
    serviceNotifications: 'Сервисные оповещения',
    notificationsFromEquipment: 'Параметры для оповещений',
    lackOfData: 'Отсутствие данных более',
    lackOfPower: 'Отсутствие питания более',
    verificationExpires: 'Истекает срок поверки через',
    timeIntervalHours: {
        zero: 'часов',
        one: 'часа',
        two: 'часов',
        few: 'часов',
        many: 'часов',
        other: 'часов'
    },
    timeIntervalDays: {
        zero: 'дней',
        one: 'день',
        two: 'дня',
        few: 'дня',
        many: 'дней',
        other: 'дней'
    },
    locationSelection: 'Выбор локации',
    point: 'Точка',
    region: 'Область',

    statusActive: 'активная',
    statusDeactivated: 'неактивная',
    statusActive2: 'Активное',
    statusDeactivated2: 'Остановлено',
    excessLevelUnits: 'ПДКмр',
    excessLevelReport: 'Уровень превышения ПДКмр',
    qmax: 'q<sub>М</sub>',

    errMsgTitleRequired: 'Необходимо задать имя',
    errMsgValueMustBeGreaterThanZero: 'Необходимо задать значение больше нуля',
    errMsgValueMustBeIntegerNumber: 'Необходимо задать целое число',
    errMsgIncorrectEmails: 'Неправильный формат списка email адресов',
    errMsgMissingEmail: 'Необходимо внести хотя бы один email адрес',

    error: 'Ошибка',

    tooltipEventIcon: {
        measurements: 'Оповещение об измерениях',
        service: 'Сервисное оповещение'
    },

    tooltipEventTitle: 'Настроить оповещение',

    tooltipSettingsHintValues: 'Вы&nbsp;можете задать абсолютное значение <br>ПДК для выбранных параметров',

    tooltipSettingsHintDailyReport: 'Вся информация о превышениях<br> в ежесуточном отчете (детализация: 20 мин)',
    tooltipDeviceDailyReport: 'Вся информация о состоянии устройств в ежесуточном отчете',

    tooltipDurationLabel: ['Не завершено', 'Завершено'],

    duration: 'длительность',

    popupDelete: {
        title: 'Хотите удалить оповещение?',
        body: 'При удалении оповещения очистится вся история превышений. Вы можете предварительно её скачать в настройках оповещения',
        cancel: COMMON.cancel,
        accept: COMMON.delete
    },

    popupDownloadFeetHistory: {
        title: 'Скачать историю',
        body: 'Выберите период, за который хотите скачать историю',
        cancel: COMMON.cancel,
        accept: 'Скачать',
        period: 'Укажите период'
    },

    durationDescription: {
        begin: 'Начало',
        continue: 'Длительность',
        expect: 'Случится'
    },

    notificationAlertLabel: {
        pdk: ['', 'превышение', 'продолжается', 'в норме'],
        nodata: ['', 'нет данных', 'нет данных', 'есть данные'],
        nopower: ['', 'нет питания', 'нет питания', 'есть питание']
    },

    alertTag: {
        endOfExcess: 'Окончание превышения',
        excess: 'Превышение',
        thereIsNoData: 'Нет данных',
        dataTransferRestored: 'Передача данных воcстановлена',
        noPowerSupply: 'Нет питания',
        powerRestored: 'Питание восстановлено',
        online: 'Online',
        offline: 'Offline',
        onBattery: 'От батареи',
        onNetworks: 'От сети'
    },

    statusTexts: {
        pdk: ['Уровень вещества превысил', 'Все концентрации вернулись к норме'],
        nodata: ['Отсутствуют данные более', 'Передача данных восстановлена'],
        nopower: ['Отсутствует питание более', 'Питание восстановлено']
    },

    showFirstEvent: 'показать начало',
    eventsFilterPrompt: 'Имя оповещения, устройство, пост или вещество'
};

export const CONFIG = {
    title: 'Настройки',
    coeff: 'Поправочные коэффициенты',
    token: 'Ключи доступа API',
    history: 'История активности',
    notifications: 'Оповещения',
    settings: 'Системные настройки',

    coeffTooltip: `Локальный поправочный коэффициент изменяет только данные,<br>
 формирующие графики с постов мониторинга. Первичные данные,<br>
 полученные с устройств мониторинга, остаются неизменными <br>
 доступны в личном кабинете и через API для скачивания`,

    tokenTooltip: `Уникальные идентификаторы (токены) предназначены<br>
 для взаимодействия с серверами платформы CityAir<br>
  без использования Web-интерфейса, посредством API`,

    historyTooltip: `История активности показывает информацию об истории входов<br>
 в ваш личный кабинет. Если вы подозреваете, что кто-то получил<br>
 доступ к вашему аккаунту, мы рекомендуем сменить пароль<br>
 и обратиться в службу безопасности`,

    measureSchemeDescription: `Сейчас эта настройка работает только в разделе «Посты и устройства». Мы сообщим вам,
    когда расширим эту настройку и на другие модули.`,

    browserTokenItems: ['Последнее использование:', 'Дата создания:', 'Количество запросов:'],
    closeToken: 'Завершить сессию',
    activeToken: 'Текущая сессия',
    closedToken: 'Завершённая сессия',
    closeAll: 'Завершить все сеансы',

    active: 'активный',

    rename: 'Перименовать',
    close: 'Удалить',
    countTokens: 'Активных ключей',
    create: 'Создать ключ',

    titlePopupCreate: 'Создать ключ',
    titlePopupClose: 'Вы действительно хотите удалить данный ключ доступа API?',
    titlePopupRename: 'Переименовать ключ',

    applyCreate: 'Создать',
    applyClose: 'Удалить',
    applyRename: 'Переименовать',

    closeDescription: 'Ключ: ',
    createDescription: 'Задайте имя ключа API',
    copySuccess: 'Ключ API скопирован',
    copy: 'Копировать',

    language: 'Язык',
    timeZone: 'Часовой пояс',
};

export const PLUMES = {
    runResults: 'Результаты расчёта',
    runConfiguration: 'Расчёт',
    controlPoints: 'Контрольные точки',
    resultsAt: 'от',
    addControlPoint: 'Добавить контрольную точку',
    addSource: 'Добавить источник',
    nowOnMap: 'Сейчас на карте',
    noRunSelected: 'Расчёт не выбран',
    forecastOn: 'Прогноз на',
    createControlPointTitle: 'Добавление контрольной точки',
    editControlPointTitle: 'Редактирование контрольной точки',
    editRunConfigurationTitle: 'Редактирование расчёта',
    latitude: 'Широта',
    longitude: 'Долгота',
    title: 'Название',
    value: 'Значение',
    runConfigurationName: 'Название расчёта',
    runConfigurationStatus: ['В работе', 'Остановлен'],
    interval: 'Периодичность',
    intervalUnits: 'мин',
    editRunConfigurationAction: 'Редактировать расчёт',
    nameRequiredError: 'Введите название расчёта',
    maxLength: (max: number) =>  `Название расчета не должно превышать ${max} символов`,
    stopRunConfigurationAction: 'Остановить расчёт',
    startRunConfigurationAction: 'Возобновить расчёт',
    sourcesListTitle: 'Источники в расчёте',
    runInterval: 'Периодичность расчёта',
    day: 'Дни',
    hour: 'Часы',
    min: 'Минуты',
    runIntervalDescription: 'Расчёт будет запускаться',
    noRunsAvailable: 'Расчётов пока нет',
    selectedPeriodNoRunsAvailable: 'За выбранный период расчётов нет',
    popupDelete: {
        title: 'Удаление контрольной точки',
        body: 'Вы действительно хотите удалить контрольную точку «%s»?',
        cancel: COMMON.cancel,
        accept: COMMON.delete
    },
    timeIntervalHours: ['час', 'часа', 'часов'],
    every: {
        zero: 'каждые',
        one: 'каждый',
        two: 'каждые',
        few: 'каждые',
        many: 'каждые',
        other: 'каждые'
    },
    previousRun: 'Предыдущий расчёт',
    nextRun: 'Следующий расчёт'
};

export const DASHBOARD = {
    dashboardTitle: 'Монитор состояния сети',
    refreshData: 'Обновить',
    availabilityStatsTitle: 'Связь и питание',
    monthlyStatsTitle: 'Статистика за',
    packetsMonitorTitle: 'Доставка данных от устройств',
    packets: {
        zero: 'пакетов',
        one: 'пакет',
        two: 'пакета',
        few: 'пакета',
        many: 'пакетов',
        other: 'пакетов'
    },
    lowBattery: 'Нет питания',
    lowSignal: 'Низкий сигнал',
    noPackets: 'Нет данных',
    showInMonitor: 'Показать',
    dataTransmissionTitle: 'Передача данных',
    dataTransmissionStatus: ['offline', 'online'],
    powerSupplyTitle: 'Питание',
    powerSupplyStatus: ['работает от батареи', 'работает от сети'],
    monthlyStatsChartTitle: 'Доставка данных со всех устройств',
    dataReceived: 'данных получено',
    deviceMonitorLegend: {
        dataReceived: 'Полученные данные',
        hasMissingData: 'Отсутствующие данные',
        poweredByBattery: 'Питание от батареи',
        lowGMSSignal: 'Низкий сигнал GSM'
    },
    availabilityTooltipText: 'Данные последнего <br/>пакета',
    monthlyStatsTooltipText: 'Общее соотношение доставленных <br/>данных со всех устройств',
    selectedTimeIntervalLabel: 'Период для отображения',
    selectedDevicesLabel: 'Устройства',
    columnLabels: {
        device: 'Устройство',
        monitoringObject: 'Пост',
        status: 'Статус',
        packetsRate: 'Количество пакетов в час'
    },
    searchDevicePlaceholderText: 'Искать устройство',
    selectAllDevicesFilter: 'Выбрать все',
    resetDevicesFilter: 'Сбросить фильтр',
    notSelectedStatusFilter: 'не выбрано'
};

export const OVERLAY = {
    hotspotsTooltip: {
        title: 'Точки тепловых аномалий VIIRS',
        text: [
            'Красными точками отмечены области детектируемых космическими аппаратами тепловых аномалий: природные пожары, а&nbsp;также действующие вулканы, промышленные объекты и&nbsp;другие сильно нагретые области на&nbsp;поверхности Земли.'
        ],
        legend: {
            current: 'текущие термоточки',
            previous: 'термоточки предыдущего дня'
        },
        note: 'На карте отображаются объединенные данные за последние 48 часов, предоставляемые NASA Fire Information for Resource Management System (FIRMS) (<a href="https://earthdata.nasa.gov/firms" target="_blank">https://earthdata.nasa.gov/firms</a>) — проектом, реализуемым на инфраструктуре NASA Earth Observing System Data and Information System (EOSDIS).',
    },
    modellingTooltip: {
        title: 'Результаты моделирования',
        text: [
            'Это значит, что сейчас мы щепетильно сравниваем результаты построений наших математических моделей с данными, полученными со станций CityAir в этом регионе.',
            'Чуть позже точность расчетов станет близкой к реальным показателям загрязненности воздуха.'
        ],
        note: 'Внимание: математические модели работают в экспериментальном режиме',
        noteDisabled: 'В данной области расчеты пока не осуществляются'
    }
};

export const POSTS_AND_DEVICES = {
    postAndDevices: 'Посты и устройства',
    addPost: 'Добавить пост мониторинга',
    viewInformation: 'Смотреть информацию',
    loadPrimaryData: 'Скачать первичные данные',
    downloadDataFromPost: 'Скачать данные с поста',
    downloadDataFromPosts: 'Скачать данные с постов',
    downloadTZA4Report: 'Скачать отчет ТЗА4',
    tza4: 'Отчет ТЗА4',
    edit: 'Редактировать',
    replaceDevice: 'Заменить устройство',
    deletePost: 'Удалить пост',
    dataDevice: 'Данные устройства',
    noMonitoringPosts: 'Пока нет постов мониторинга',
    noDevices: 'Пока нет Устройств',
    unfortunatelyNothing: 'К сожалению, ничего',
    searchPost: 'Искать пост, устройство, локацию',
    searchDevice: 'Искать пост, устройство',
    post: 'Пост',
    posts: 'Посты',
    postsMonitoring: 'Посты мониторинга',
    location: 'Локация',
    aqi: 'AQI',
    device: 'Устройство',
    devices: 'Устройствa',
    type: 'Тип',
    state: 'Состояние',
    nameOnMap: 'Имя на карте',
    coordinate: 'Координаты',
    timeZone: 'Часовой пояс',
    dataSources: 'Источники данных',
    actionHistory: 'История действий',
    users: 'Пользователи',
    documentation: 'Документация',
    showMore: 'показать больше',
    showLess: 'показать меньше',
    administrator: 'Администратор',
    operator: 'Оператор',
    power: 'Питание',
    dataTransfer: 'Передача данных',
    info: 'Информация',
    online: 'Online',
    offline: 'Offline',
    relatedDevices: 'Связанные устройства',
    name: 'Название',
    description: 'Описание',
    locationOnTheMap: 'Расположение на карте',
    createPost: 'Создать пост',
    editPost: 'Редактировать пост',
    newMO: 'Новый пост мониторинга',
    editMo: 'Редактирование поста мониторинга',
    dataFromPost: 'Данные с постов мониторинга',
    intervalData: 'Интервал данных',
    units: 'Единицы измерения',
    pightsOfPDK: 'доли ПДК',
    loadingDataForThePrevious: 'Загрузка данных за предыдущий период может занять некоторое время',
    format: 'Формат',
    allAdministratorsAndOperators: 'Доступ к&nbsp;данным с&nbsp;поста мониторинга имеют все администраторы и&nbsp;операторы',
    asWellAsTheFollowing: 'а&nbsp;также следующие наблюдатели:',
    accessToData: 'Доступ к данным с поста мониторинга'
};

export const INDOOR = {
    [ROOM]: 'Комната',
    selectRoom: 'Выберите комнату для отображения информации',
    iaq: 'IAQI - индекс качества воздуха в помещении',
    search: 'Поиск',
    comfort: 'Comfort',
    statTitle: 'Summary for all offices',
    safety: 'Safety',
    title1: 'Good air, time',
    title2: 'IAQI’',
    popupComfort: `Here we&nbsp;calculate the percentage of&nbsp;time during the work day when the air in&nbsp;the space was comfortable and contributed to&nbsp;productive work and <nobr>well-being</nobr>.
<br /><br />
Comfort is&nbsp;determined by&nbsp;the temperature, humidity and carbon dioxide levels.`,
    popupSafety: `Here we&nbsp;show summary statistics based on&nbsp;the IAQI in&nbsp;the rooms you manage: the percentage of&nbsp;time that air quality was high, as&nbsp;well as&nbsp;the average index per day.
<br /><br />
To&nbsp;calculate IAQI we&nbsp;use <nobr>real-time</nobr> data on&nbsp;the concentrations of&nbsp;PM2.5, PM10 and carbon dioxide CO2.`,
};
export const INDOOR_WIDGET_STATUS = {
    statusAQI: [
        'Воздух чистый и свежий, комфортный для продуктивной работы.',
        'Воздух весьма тяжёлый. Стоит проветрить, если за окном свежо.',
        'Качество воздуха критически низкое. Необходимо принять меры.',
    ],
    offlineStatus: 'Станция мониторинга временно отключена',
    noData: 'Нет данных',
};
export const ANALYTICS_COMPONENT = {
    tooltip: (index: number, measure: string) => [
        `Общая доля каждого значения ${measure}`,
        `Средние значения ${measure} по часам суток за всю историю наблюдений`,
        `Средние значения ${measure} по дням недели за всю историю наблюдений`
    ][index],
};

export const FEEDBACK = {
    leaveFeedback: 'Написать нам',
    formTitle: 'Поделитесь вашим мнением',
    formDescription: 'Мы будем благодарны, если вы напишите свои пожелания по улучшению нашего сервиса',
    formPlaceholder: 'Текст сообщения',
    submit: 'Отправить',
    requiredField: 'Это поле нужно заполнить',
    successTitle: 'Спасибо! Ваше сообщение отправлено',
    successMessage: 'Ваш отзыв будет использован для улучшения нашего сервиса.',
};

export const AQI_DETAILED_INFO = {
    tooltip: 'Индекс качества воздуха по шкале от 1 (чистый) до 10<br/> (очень грязный). Отражает качество воздуха в текущий<br/> момент.',
    gotoFAQ: 'Подробнее об индексе',
    title: 'Instant AQI',
    description: 'Отражает качество воздуха на текущий момент',
    legendTitle: 'Значения шкалы определяются следующим образом',
    dangerLevelLow: 'безопасно для всех',
    dangerLevelMedium: 'терпимо, но могут быть негативные реакции у больных сердечно-сосудистыми заболеваниями, аллергиями, астмой',
    dangerLevelHigh: 'опасно для всех',
};
export const REPORTS = {
    mainTitle: 'Отчёты',
    analiticTitle: 'Аналитика',
    reportsListTitle: 'Отчеты',
    compareTitle: 'Сравнение данных',
    searchText: 'Поиск отчета',
    settingsAutoText: 'Настроить расписание отчётов',
    createReportTitle: 'Сформировать отчёт',
    historyListTitle: 'История отчётов',
    checkAsReadTitle: 'Отметить все как прочитанные',
    filter: 'Фильтр',
    hTableHeaderFormat: 'Формат',
    hTableHeaderName: 'Название',
    hTableHeaderCreate: 'Дата создания',
    hTableHeaderUser: 'Кем создан',
    hTableHeaderComment: 'Комментарий',
    hTableHeaderStatus: 'Статус',
    hDetailHeader: 'Посты мониторинга',
    CREATED: 'Создан',
    QUEUED: 'На очереди',
    IN_PROGRESS: 'Формируется',
    ERROR: 'Ошибка',
    READY: 'Готов',
    autoGenerate: 'Автоматически',
    clearFilter: 'Сбросить фильтр',
    emptyReport: 'Отчеты не найдены',
    generateReport: 'Сформировать',
    noFoundKind: 'Форма отчета не найдена',
    returnReportButtonText: 'К списку отчетов',
    noFoundKindReturn: 'Сейчас вернёмся к списку отчётов',
    noReportByPeriod: 'За выбранный период отчётов нет',
    filterPeriod: 'Дата создания',
    formCreate: {
        name: 'Название',
        nameError: 'Введите название отчета',
        postMoError: 'Поле не может быть пустым',
        calendar: 'Отчетный период',
        commentPlaceholder: 'Комментарий',
        maxLength: (field: string, max: number) => {
            if (field === 'name') {
                return `Название отчета не должно превышать ${max} символов`;
            } else if (field === 'comment') {
                return `Комментарий не должен превышать ${max} символов`;
            }

            return `Данное поле не должено превышать ${max} символов`;
        },
        max: (field: string, max: number) => `Значение не должно превышать ${max}`,
        min: (field: string, min: number) => `Значение не должно быть меньше ${min}`,
        errorTitle: 'Ошибка',
        tryAgain: 'Попробуйте ёще раз',
        search: {
            timezone: 'Искать часовой пояс',
        },
    },
    keyParams: {
        available_formats: 'Формат',
        measure: 'Вещества',
        timezone: 'Часовой пояс',
        year: 'Выберите год',
    },
    measure: 'Вещество',
    titleDeletePopup: (name: string) => `Вы действительно хотите удалить отчет «${name}»?`,
    titleDeleteCommentPopup: (name: string) => `Вы действительно хотите удалить комментарий к отчету «${name}»?`,
    titleRenamePopup: 'Переименовать отчет',
    titleEditComment: 'Редактирование комментария к отчёту',
    titleAddComment: 'Добавление комментария к отчёту',
    addComment: 'добавить',
    bodyDeletePopup: '',
    cancel: COMMON.cancel,
    accept: COMMON.delete,
    reports: {
        zero: 'отчётов',
        one: 'отчёт',
        two: 'отчёта',
        few: 'отчёта',
        many: 'отчётов',
        other: 'отчётов',
    },
    countReportText: (count: number) => `Нашли ${count}`,
    morePostText: (count: number) => `ещё ${count} `,
    messages: {
        ERROR: {
            title: 'Ошибка',
            text: (name: string) => `При формировании отчёта «${name}» произошла ошибка`
        },
        READY: {
            title: (name: string) => `Отчёт «${name}» готов`,
        },
        IN_PROGRESS: {
            title: (name: string) => `Отчёт «${name}» формируется`,
        }
    },
    messagesEdit: {
        ERROR: {
            title: 'Ошибка',
            text: (name: string) => `При сохранинии изменении отчёта «${name}» произошла ошибка`
        },
        READY: {
            title: 'Изменения успешно сохранены',
        }
    },
    messageDeleteSuccess: 'Отчет успешно удален',
    messageDeleteError: 'Ошибка при удалении отчета',
    rename: 'Переименовать',
    edit: 'Редактировать',
    save: 'Сохранить',
    totalReports: 'Всего отчётов',
    error403: 'Нет прав для данного действия',
};
export const FORECAST = {
    mainTitle: 'Прогнозирование',
    controlPointsTitle: 'Контрольные точки',
    addControlPoint: 'Добавить контрольную точку',
    editControlPoint: 'Редактирование контрольной точки',
    newControlPoint: 'Новая контрольная точка',
    nameLabel: 'Название',
    latLabel: 'Широта',
    lngLabel: 'Долгота',
    errorRequired: 'Обязательное значение',
    nameRequiredError: 'Введите название контрольной точки',
    minError: 'Mинимальное значение',
    maxError: 'Максимальное значение',
    maxLength: (max: number) =>  `Имя контрольной точки не должно превышать ${max} символов`,
    titleDeletePopup:(name: string) => `Вы действительно хотите удалить контрольную точку «${name}»?`,
    bodyDeletePopup: 'Вы можете позже заново создать контрольную точку, данные в ней загрузятся с момента начала расчётов',
    cancel: COMMON.cancel,
    accept: COMMON.delete,
    deleteList: 'Удалить контрольную точку',
    edit: EDIT_STATION.edit,
    errorTitle: 'Ошибка',
    tryAgain: 'Попробуйте ёще раз',
    noData: 'Нет контрольных точек',
    errorListLoad: 'Что-то пошло не так, попробуйте позже',
    errorLoadList404: 'Что-то пошло не так, обратитесь в тех.поддержку',
};

export const ONBOARDING = {
    doNotShowAgain: 'Больше не показывать',
    before: 'Было',
    after: 'Стало',
    newMapStyle: {
        title: 'Карты CityAir стали подробнее',
        description: [
            'Теперь вы сможете видеть рельеф местности, леса, поля, болота, сельхозобъекты и границы городов.'
        ],
        imgUrl: 'onboarding/new-map-style-07-2022/new-map-style-after.jpg',
        imgUrlCompare: 'onboarding/new-map-style-07-2022/new-map-style-before.jpg',
    }
};
export const INFO_MESSAGE = {
    internetOff: 'Отсутствует интернет-соединение'
};
