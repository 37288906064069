import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'forms',
        pathMatch: 'full'
    },
    {
        path: 'forms',
        loadChildren: () => import('./pages/forms-page/forms-page.module').then(m => m.FormsPageModule)
    },
    {
        path: 'menu',
        loadChildren: () => import('./pages/menu-page/menu-page.module').then(m => m.MenuPageModule)
    },
    {
        path: 'cards',
        loadChildren: () => import('./pages/cards-page/cards-page.module').then(m => m.CardsPageModule)
    },
    {
        path: 'markers',
        loadChildren: () => import('./pages/markers-page/markers-page.module').then(m => m.MarkersPageModule)
    },
    {
        path: 'tooltips',
        loadChildren: () => import('./pages/tooltips-page/tooltips-page.module').then(m => m.TooltipsPageModule)
    },
    {
        path: 'misc',
        loadChildren: () => import('./pages/misc-page/misc-page.module').then(m => m.MiscPageModule)
    },
    {
        path: 'buttons',
        loadChildren: () => import('./pages/buttons-page/buttons-page.module').then(m => m.ButtonsPageModule)
    },
    {
        path: 'static',
        loadChildren: () => import('./pages/static-page/static-page.module').then(m => m.StaticPageModule)
    },
    {
        path: 'timeline',
        loadChildren: () => import('./pages/timeline-page/timeline-page.module').then(m => m.TimelinePageModule)
    },
    {
        path: '**',
        redirectTo: '/forms'
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled'
        }),
    ],
    exports: [
        RouterModule
    ]
})
export class RoutingModule {}
